import {
  deleteItem,
  getItem,
  postItem,
  putItem,
} from '../../../api/api-methods';

const requestOptions = {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
};

/**
 * Crea una imagen
 * @param {FormData} imageFormData formData con los datos de imagen
 * @returns
 */
export const postImage = async (imageFormData) => {
  try {
    const response = await postItem(
      '/app-personas/imagen',
      imageFormData,
      requestOptions
    );
    if (response.e) {
      return {
        ok: false,
        message: 'No se pudo crear la imagen. ' + response.e,
      };
    }
    return { ok: true, data: response };
  } catch (error) {
    return { ok: false, message: 'Error al intentar crear imagen. ' + error };
  }
};

/**
 * Actualiza una imagen
 * @param {FormData} imageFormData formData con los datos de la imagen a actualizar. Debe incluir id
 * @returns
 */
export const putImage = async (imageFormData) => {
  try {
    const id = imageFormData.get('id');
    const response = await putItem(
      `/app-personas/imagen/${id}`,
      imageFormData,
      requestOptions
    );
    if (response.e) {
      return {
        ok: false,
        message: 'No se pudo actualizar la imagen. ' + response.e,
      };
    }
    return { ok: true, data: response };
  } catch (error) {
    return {
      ok: false,
      message: 'Error al intentar actualizar imagen. ' + error,
    };
  }
};

/**
 * Elimina una imagen
 * @param {Number} id Id de la imagen a eliminar
 * @returns
 */
export const delImage = async (id) => {
  try {
    const response = await deleteItem('/app-personas/imagen', id);
    if (response.e) {
      return {
        ok: false,
        message: 'No se pudo eliminar la imagen. ' + response.e,
      };
    }
    return { ok: true, data: 'Imagen eliminada exitosamente' };
  } catch (error) {
    return {
      ok: false,
      message: 'Error al intentar eliminar imagen. ' + error,
    };
  }
};

/**
 * Crea un documento
 * @param {FormData} documentFormData formData con los datos del documento a crear
 * @returns
 */
export const postDocument = async (documentFormData) => {
  try {
    const response = await postItem(
      '/app-personas/documento',
      documentFormData,
      requestOptions
    );
    if (response.e) {
      return {
        ok: false,
        message: 'No se pudo crear el documento. ' + response.e,
      };
    }
    return { ok: true, data: response };
  } catch (error) {
    return {
      ok: false,
      message: 'Error al intentar crear documento. ' + error,
    };
  }
};

/**
 * Actualiza un documento
 * @param {FormData} documentFormData formData con los datos de la imagen a actualizar. Debe incluir id
 * @returns
 */
export const putDocument = async (documentFormData) => {
  try {
    const id = documentFormData.get('id');
    const response = await putItem(
      `/app-personas/documento/${id}`,
      documentFormData,
      requestOptions
    );
    if (response.e) {
      return {
        ok: false,
        message: 'No se pudo actualizar el documento. ' + response.e,
      };
    }
    return { ok: true, data: response };
  } catch (error) {
    return {
      ok: false,
      message: 'Error al intentar actualizar documento. ' + error,
    };
  }
};

/**
 * Elimina un documento
 * @param {Number} id id del documento a eliminar
 * @returns
 */
export const delDocument = async (id) => {
  try {
    const response = await deleteItem('/app-personas/documento', id);
    if (response.e) {
      return {
        ok: false,
        message: 'No se pudo eliminar el documento. ' + response.e,
      };
    }
    return { ok: true, data: 'Documento eliminado exitosamente' };
  } catch (error) {
    return {
      ok: false,
      message: 'Error al intentar eliminar documento. ' + error,
    };
  }
};

/**
 * Comprueba la disponibilidad de un correo
 * @param {string} email Correo a verificar
 * @returns ok true o false
 */
export const checkEmailAvailability = async (email) => {
  try {
    const url = `/app-personas/helpers/correo-disponible/${email}`;
    const response = await getItem(url);
    const { is_disponible } = response;
    return { ok: is_disponible };
  } catch (error) {
    return {
      ok: false,
      message: 'Error al intentar checar disponibilidad de correo. ' + error,
    };
  }
};
